var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{ref:"active",staticClass:"wizard-vertical mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.updateInfo}},[_c('tab-content',{attrs:{"title":"Account Details","icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Account Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter Your Account Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Level/Rank","label-for":"Rank"}},[_c('b-form-input',{attrs:{"id":"name","disabled":""},model:{value:(_vm.data.rank_name),callback:function ($$v) {_vm.$set(_vm.data, "rank_name", $$v)},expression:"data.rank_name"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Discount Plan Name","label-for":"plan"}},[_c('b-form-input',{attrs:{"id":"name","disabled":""},model:{value:(_vm.data.plan_name),callback:function ($$v) {_vm.$set(_vm.data, "plan_name", $$v)},expression:"data.plan_name"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Full Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Enter name"},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":" Account Type","label-for":"account_type"}},[_c('v-select',{attrs:{"id":"account_type","placeholder":"Select Account Type","options":_vm.accountTypeOptions,"reduce":function (val) { return val.value; }},on:{"input":_vm.showHideCompany},model:{value:(_vm.data.account_type),callback:function ($$v) {_vm.$set(_vm.data, "account_type", $$v)},expression:"data.account_type"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Passport/ID Number","label-for":"passport_no"}},[_c('b-form-input',{attrs:{"id":"linked-in"},model:{value:(_vm.data.passport_no),callback:function ($$v) {_vm.$set(_vm.data, "passport_no", $$v)},expression:"data.passport_no"}})],1)],1),(_vm.showIsCompany)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company Name","label-for":"firm_name"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firm_name","state":errors.length > 0 ? false:null,"placeholder":"Company Name"},model:{value:(_vm.data.firm_name),callback:function ($$v) {_vm.$set(_vm.data, "firm_name", $$v)},expression:"data.firm_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4257786934)})],1)],1):_vm._e(),(_vm.showIsCompany)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company Registration No","label-for":"firm_reg_no"}},[_c('validation-provider',{attrs:{"name":"Firm registartion number+","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firm_reg_no","state":errors.length > 0 ? false:null},model:{value:(_vm.data.firm_reg_no),callback:function ($$v) {_vm.$set(_vm.data, "firm_reg_no", $$v)},expression:"data.firm_reg_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3359290883)})],1)],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":"Address","icon":"feather icon-map-pin","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Address ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter Your Address.")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address1","label-for":"address1"}},[_c('validation-provider',{attrs:{"name":"Address1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"placeholder":"Enter Address Lin 1"},model:{value:(_vm.data.address),callback:function ($$v) {_vm.$set(_vm.data, "address", $$v)},expression:"data.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address2","label-for":"address2"}},[_c('validation-provider',{attrs:{"name":"Address2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"placeholder":"Enter Address Lin 2"},model:{value:(_vm.data.address2),callback:function ($$v) {_vm.$set(_vm.data, "address2", $$v)},expression:"data.address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Postal Code","label-for":"postcode"}},[_c('validation-provider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","state":errors.length > 0 ? false:null,"type":"number","placeholder":"Enter Post Code"},model:{value:(_vm.data.postcode),callback:function ($$v) {_vm.$set(_vm.data, "postcode", $$v)},expression:"data.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false:null,"placeholder":"Enter City Name"},model:{value:(_vm.data.city),callback:function ($$v) {_vm.$set(_vm.data, "city", $$v)},expression:"data.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"country","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country","placeholder":"Select Country","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.data.country),callback:function ($$v) {_vm.$set(_vm.data, "country", $$v)},expression:"data.country"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"State","label-for":"state","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"State","placeholder":"Select State","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.stateOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.data.state),callback:function ($$v) {_vm.$set(_vm.data, "state", $$v)},expression:"data.state"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Other Info","icon":"feather icon-info","before-change":_vm.validationOtherInfo}},[_c('validation-observer',{ref:"otherInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Other Info ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter Your Other Info")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date of birth","label-for":"dob"}},[_c('b-form-datepicker',{attrs:{"id":"example-datepicker","date-format-options":{ year: 'numeric', month: 'numeric', day: '2-digit'},"locale":"en"},model:{value:(_vm.data.date_of_birth),callback:function ($$v) {_vm.$set(_vm.data, "date_of_birth", $$v)},expression:"data.date_of_birth"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"gender","placeholder":"Select gander","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.genderOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.data.gender),callback:function ($$v) {_vm.$set(_vm.data, "gender", $$v)},expression:"data.gender"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nationality","label-for":"Nationality"}},[_c('b-form-input',{attrs:{"id":"Nationality"},model:{value:(_vm.data.nationality),callback:function ($$v) {_vm.$set(_vm.data, "nationality", $$v)},expression:"data.nationality"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Religion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Religion","label-for":"Religion","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"Religion","placeholder":"Select Religion","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.religionOptions,"reduce":function (val) { return val.value; }},model:{value:(_vm.data.religion),callback:function ($$v) {_vm.$set(_vm.data, "religion", $$v)},expression:"data.religion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Notification Balance","label-for":"min_balance"}},[_c('b-form-input',{attrs:{"id":"min_balance"},model:{value:(_vm.data.min_balance),callback:function ($$v) {_vm.$set(_vm.data, "min_balance", $$v)},expression:"data.min_balance"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Notification Email","label-for":"notification_email"}},[_c('b-form-input',{attrs:{"id":"notification_email"},model:{value:(_vm.data.notification_email),callback:function ($$v) {_vm.$set(_vm.data, "notification_email", $$v)},expression:"data.notification_email"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import ApiService from '../common/api.service'

import decrypt from '../common/decrypt'

class CommonService {
  getState() {
    return ApiService.get('/state').then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }

  getCoutry() {
    return ApiService.get('/country').then(response => Promise.resolve(decrypt(response.data.data)), error => Promise.reject(error))
  }
   keyRemove() {
    return ApiService.get('/key/remove').then(response => Promise.resolve(response.data.data), error => Promise.reject(error))
  }
}

export default new CommonService()
